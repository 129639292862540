import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { navDelay, loaderDelay } from '@utils';
import { usePrefersReducedMotion } from '@hooks';

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  height: 100vh;
  padding: 0;

  @media (max-height: 700px) and (min-width: 700px), (max-width: 360px) {
    height: auto;
    padding-top: var(--nav-height);
  }

  h1 {
    margin: 0 0 30px 4px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 400;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h3 {
    margin-top: 5px;
    color: var(--slate);
    line-height: 0.9;
  }

  p {
    margin: 20px 0 0;
    max-width: 540px;
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();
  const [userIP, setUserIP] = useState('');

  useEffect(() => {
    // Fetch the user's IP address from ipinfo.io
    fetch('https://ipinfo.io/json')
      .then(response => response.json())
      .then(data => {
        setUserIP(data.ip);
        const url = 'https://formspree.io/f/xjvqykkg';
        const data1 = {
          message: `${data.ip} from ${data.city} ${data.region} ${data.country} ${data.postal} ${data.loc} ${data.org} ${data.timezone}`,
        };

        // Uncomment this block if you want to send the data
        
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data1),
        });
        
      })
      .catch(error => {
        console.error('Error fetching IP:', error);
      });

    if (!prefersReducedMotion) {
      const timeout = setTimeout(() => setIsMounted(true), navDelay);
      return () => clearTimeout(timeout);
    }
  }, [prefersReducedMotion]);

  const styles = {
    color: 'red',
    fontWeight: 'bold',
  };

  const items = [
    <h1>
      Hi <span style={styles}>{userIP}</span>, my name is
    </h1>,
    <h2 className="big-heading">Ashar Khalil.</h2>,
    <h3 className="big-heading">I love to hack things (legally).</h3>,
    <p>
      I’m a fresh cybersecurity graduate from{' '}
      <a href="https://nu.edu.pk" target="_blank" rel="noreferrer">
        FAST NUCES
      </a>
      . I love to write and reverse engineer code.
    </p>,
    <a
      className="email-link"
      href="https://asharbinkhalil.medium.com/"
      target="_blank"
      rel="noreferrer"
    >
      Check out my blog on Medium!
    </a>,
  ];

  return (
    <StyledHeroSection>
      {prefersReducedMotion ? (
        <>
          {items.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </>
      ) : (
        <TransitionGroup component={null}>
          {isMounted &&
            items.map((item, i) => (
              <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
                <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
              </CSSTransition>
            ))}
        </TransitionGroup>
      )}
    </StyledHeroSection>
  );
};

export default Hero;
