import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = [
    'Web Application Security Testing',
    'Mobile Application Security Testing',
    'BurpSuite',
    'Openvas, Nmap',
    'Ghidra / IDA PRO',
    'OSINT, GEOINT, HUMINT',
    'C/C++, Python, Java, JavaScript',
    'Amazon Web Services',
    'Docker',
    'Kubernetes',
  ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              I graduated from FAST in 2024, At FAST I kick off my journey by core computer science courses
              for the first two years, which really solidified my foundation.
              <br />
              <br />
              My sophomore year sparked a passion for digital forensics and OSINT. By junior year, I
              was deep into hacking—ethically breaching notable applications in Pakistan and
              reporting my findings.
              <br />
              <br />
              I specialized in red teaming, tackling web and mobile application security testing
              projects and working on over 25 applications.
              <br />
              <br />
              Soon after graduation, I joined Emumba as an SDET, where I dove into implementing
              DevSecOps practices, integrating security testing tools into CI/CD pipelines, and
              collaborating with my teammates to foster secure coding practices. It was there that I
              discovered my interest in AI, testing LLMs for prompt injection attacks and exploring
              various LLM QA tests using Langchain tools.
              <br />
              <br />
              I also work part-time at OSINT Industries, where I love researching and developing
              email and phone reverse lookup modules. It’s an exciting challenge that keeps me
              engaged!
              <br />
              <br />
              <a href="/Certifications/">See all Certifications here</a>
            </p>
          </div>
          <br />
          <p>Here are a few technologies I have been working with recently:</p>
          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/me.jpg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
